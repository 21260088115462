<template>
    <seccion-datos :titulo="provs" :botonGuardar="false" tipo="info">
        <label>{{ $t('general.integrarprovincias') }}</label>
        <select class="custom-select form-control-sm select2 provincias">
            <option value="0">{{ $t('general.seleccioneprovincias') }}</option>
            <option :value="provincia.id" v-for="provincia in todasProvincias" :key="'provincia-' + provincia.id">{{ provincia.nombre_provincia }}</option>
        </select>
        <br>
        <div class="row">
            <div class="col-md-8">
                <table class="table  table-sm table-striped">
                    <tbody>
                        <tr v-for="provincia in provincias" :key="'provincia-asignada-' + provincia.id_provincia">
                            <td width="80%">{{ provincia.nombre_provincia }}</td>
                            <td><button type="button" class="btn btn-block btn-outline-danger btn-xs" @click="eliminar(provincia.id_provincia)">{{ $t('general.eliminar') }}</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </seccion-datos>
</template>
<script>
import $ from 'jquery';
export default {
    inject: ['agregarProvincia', 'eliminarProvincia'],
    props: {
        datos: {
            type: Object
        },
    },
    data() {
        return  {
            provincias: [],
            provs:''
        }
    },
    computed: {
        todasProvincias() {
            let aux = this.$store.getters['datosApp/provincias'];
            return aux.datos;
        }
    },
    watch: {
        datos() {
            this.init();
        }
    },
    methods: {
        init() {
            if (this.datos) {
                for (let dato in this.$data) {
                    if (this.datos[dato] !== null && this.datos[dato] !== undefined) {
                        this[dato] = this.datos[dato];
                    }
                }
            }
            const $select = $('.select2.provincias');
            $select.select2({ theme: 'bootstrap4' });
            $select.off('select2:select').on('select2:select', (e) => {
                var data = e.params.data;
                this.agregar(data.id);
            });
            this.provs = this.$t('general.provincia') + "s"; 
        },
        agregar(idProvincia) {
            this.agregarProvincia(idProvincia);
        },
        eliminar(idProvincia) {
            this.eliminarProvincia(idProvincia);
        }
    },
    created() {
        this.init();
    },
}
</script>