<template>
    <div class="row">
        <div class="col-md-6">
            <!-- Inicio Tipo sistema -->
            <compania-sistema-tipo  
                v-bind="$props"
            /> 
            <!-- Fin Tipo sistema -->
        </div>

        <div class="col-md-6">
            <!-- Inicio provincias -->
            <compania-sistema-provincias v-bind="$props" />
            <!-- Fin provincias -->

            <!-- Inicio contratos-->
            <compania-sistema-contratos v-bind="$props" />
            <!-- Fin contratos -->

            <!-- Inicio INTERPWGS -->
            <compania-sistema-interpwgs v-bind="$props"/>
            <!-- Fin INTERPWGS -->
        </div>
    </div>
</template>
<script>
import CompaniaSistemaTipo from './secciones/CompaniaSistemaTipo.vue'
import CompaniaSistemaProvincias from './secciones/CompaniaSistemaProvincias.vue'
import CompaniaSistemaContratos from './secciones/CompaniaSistemaContratos.vue'
import CompaniaSistemaInterpwgs from './secciones/CompaniaSistemaInterpwgs.vue'
export default {
    components: {
        CompaniaSistemaTipo,
        CompaniaSistemaProvincias,
        CompaniaSistemaInterpwgs,
        CompaniaSistemaContratos,
    },
    props: {
        datos: {
            type: Object
        },
    },
    methods: {
    }
}
</script>